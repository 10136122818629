<template>
  <div class="reportIndexView">
    <div class="topView">
      <div class="tView">
        <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="统计"
        ></el-page-header>
      </div>
      <div class="dateView">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="dateView">
        <el-select
          v-model="tenantId"
          clearable
          filterable
          default-first-option
          placeholder="门店"
          @clear="getMemberCardStatistics"
        >
          <el-option
            v-for="item in storeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="dateView">
        <el-radio-group v-model="radio2" size="medium" @change="changeQuery">
          <el-radio-button label="1" border>门店</el-radio-button>
          <el-radio-button label="2" border>本人</el-radio-button>
        </el-radio-group>
      </div>
      <div class="dateView">
        <el-button type="primary" @click="getMemberCardStatistics">
          确认
        </el-button>
      </div>
      <div class="dateView">
        <el-button type="success" @click="printPaper">打印小票</el-button>
      </div>
    </div>
    <div
      style=" height: calc(100vh - 155px);
  overflow: auto;"
    >
      <div class="incomeRepView">
        <div class="payRepView">
          <div class="subTView">
            营业统计
            <span>(单位：元)</span>
          </div>
          <div
            class="contentView"
            v-loading="test"
            element-loading-text="加载中..."
          >
            <div class="chartView">
              <div class="totalAmtView">
                <div class="bcView">
                  <div style="display: flex;">
                    <div class="textView">
                      总营收
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="总营收=实收金额-退款金额"
                        placement="top"
                      >
                        <i class="el-icon-warning setStyle"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="amtView">{{ totalPic }}</div>
                </div>
              </div>
            </div>
            <div class="valView payRepListView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <label>
                  会员充值
                  <el-tooltip
                    class="item setFont"
                    effect="dark"
                    content="会员充值 = 现金充值+收钱吧充值"
                    placement="top"
                  >
                    <i class="el-icon-warning setStyle"></i>
                  </el-tooltip>
                </label>
                <div>
                  {{ memberCardUserRecord.memberRechargeSuccessAmount }}
                </div>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_1.png"
                />
                <label>
                  洗衣收入
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="洗衣收入=洗衣收钱吧+现金+美团/点评+小程序+洗后付付款"
                    placement="top"
                  >
                    <i class="el-icon-warning setStyle"></i>
                  </el-tooltip>
                </label>
                <div>{{ orderRecord.laundryAmount }}</div>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <label>
                  洗衣退款
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="洗衣退款 = 现金洗衣退款+收钱吧洗衣退款+美团/点评洗衣退款+小程序洗衣退款+口碑洗衣退款+太保洗衣退款"
                    placement="bottom"
                  >
                    <i class="el-icon-warning setStyle"></i>
                  </el-tooltip>
                </label>
                <div>
                  {{ refundRecord.clothesRefundAmount }}
                </div>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_2.png"
                />
                <label>
                  充值退款
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="充值退款 = 现金充值退款金额 + 收钱吧储值退款金额"
                    placement="bottom"
                  >
                    <i class="el-icon-warning setStyle"></i>
                  </el-tooltip>
                </label>
                <div>
                  {{ refundRecord.chuRefundAmount }}
                </div>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <label>
                  退款金额
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="退款金额 = 洗衣退款+储值退款"
                    placement="bottom"
                  >
                    <i class="el-icon-warning setStyle"></i>
                  </el-tooltip>
                </label>
                <div>
                  {{ refundRecord.totalRefundPrice }}
                </div>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_2.png"
                />
                <label>
                  实收金额
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="实收金额 = 会员充值的金额 + 洗衣收入的金额"
                    placement="bottom"
                  >
                    <i class="el-icon-warning setStyle"></i>
                  </el-tooltip>
                </label>
                <div>
                  {{
                    Number(
                      memberCardUserRecord.memberRechargeSuccessAmount +
                        orderRecord.laundryAmount
                    ).toFixed(2)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cardRepView">
          <!-- <div class="subTView">会员卡统计</div>
        <div class="listView" v-loading="test" element-loading-text="加载中">
          <div class="listItem">
            <div class="label-name">权益卡</div>
            <div class="label-cnt">{{ memberCardUserRecord.cardNum }}张</div>
            <div class="label-amt">{{ memberCardUserRecord.cardNum * 99 }}</div>
          </div>
        </div> -->
          <div class="subTView">提成统计</div>
          <div class="listView" v-loading="test" element-loading-text="加载中">
            <!-- <div class="listItem">
            <div class="label-name">权益卡</div>
            <div class="label-cnt">{{ memberCardUserRecord.cardNum }}张</div>
            <div class="label-amt">{{ memberCardUserRecord.cardNum * 99 }}</div>
          </div> -->
            <div class="totalCommission" @click="gotoPage">
              <p>
                总提成
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="总提成=收衣提成+办卡提成+充值提成"
                  placement="top"
                >
                  <i class="el-icon-warning setStyle"></i>
                </el-tooltip>
              </p>
              <p>{{ commission.totalCommission }}</p>
            </div>
            <div>
              <div @click="gotoPage">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <span>取衣提成:</span>
                <span>￥{{ commission.tackCommission }}</span>
              </div>
              <div @click="gotoPage">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <span>办卡提成:</span>
                <span>￥{{ commission.cardCommission }}</span>
              </div>
              <div @click="gotoPage">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <span>充值提成:</span>
                <span>￥{{ commission.rechargeCommission }}</span>
              </div>
            </div>
            <div>
              <div @click="gotoPage">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/Report_Pay_0.png"
                />
                <span>收衣预计提成:</span>
                <span>￥{{ commission.expectedCommission }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customerRepView">
        <div class="subTView">
          收入明细
          <span>(单位：元)</span>
        </div>
        <div class="contentView" v-loading="test" element-loading-text="加载中">
          <div class="customerTypeRepView">
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>现金洗衣</label>
                <label>{{ orderRecord.cashAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>现金充值</label>
                <label>{{ memberCardUserRecord.cashAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>收钱吧洗衣</label>
                <label>{{ orderRecord.shouAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>收钱吧充值</label>
                <label>{{ memberCardUserRecord.shouAmount }}</label>
              </div>
            </div>
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>历史卡消</label>
                <label>{{ orderRecord.blaceAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>通享卡消</label>
                <label>{{ orderRecord.universalBalanceAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>洗后付</label>
                <label>{{ orderRecord.laterAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>线上支付充值</label>
                <label>{{ memberCardUserRecord.hmAmount }}</label>
              </div>
            </div>
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>小程序</label>
                <label>{{ orderRecord.miniAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>太保团单</label>
                <label>{{ orderRecord.lianGroupBuyAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>美团/点评</label>
                <label>{{ orderRecord.meituanAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>抖音</label>
                <label>{{ orderRecord.tiktokAmount }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customerRepView">
        <div class="subTView">
          退款明细
          <span>(单位：元)</span>
        </div>
        <div class="contentView" v-loading="test" element-loading-text="加载中">
          <div class="customerTypeRepView">
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>现金洗衣退款</label>
                <label>{{ refundRecord.cashRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>收钱吧洗衣退款</label>
                <label>{{ refundRecord.shouRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>收钱吧充值退款</label>
                <label>{{ refundRecord.shouChuRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>现金充值退款</label>
                <label>{{ refundRecord.cashChuRefundAmount }}</label>
              </div>
            </div>
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>美团/点评洗衣退款</label>
                <label>{{ refundRecord.mtRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>小程序洗衣退款</label>
                <label>{{ refundRecord.miniRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>太保团单洗衣退洗</label>
                <label>{{ refundRecord.tbRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>线上支付充值退款</label>
                <label>{{ refundRecord.hmChuRefundAmount }}</label>
              </div>
            </div>
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>余额洗衣(历史卡消)退款</label>
                <label>{{ refundRecord.balanceRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>余额洗衣(通享卡消)退款</label>
                <label>{{ refundRecord.universalBalanceRefundAmount }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>权益卡退款</label>
                <label>{{ refundRecord.quanRefund }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>抖音退款</label>
                <label>{{ refundRecord.tiktokRefundAmount }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customerRepView">
        <div class="subTView">
          其他明细
        </div>
        <div class="contentView" v-loading="test" element-loading-text="加载中">
          <div class="customerTypeRepView">
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>收衣单数</label>
                <label>{{ orderRecord.receivedOrderNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>总收件衣数</label>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="总收件衣数：统计所有衣物(包括待收衣)"
                  placement="bottom"
                >
                  <i class="el-icon-question setStyle"></i>
                </el-tooltip>
                <label>{{ orderRecord.actualReceivedOrderClothesNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>实际收件衣数</label>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="实际收件衣数：只统计已收衣衣物"
                  placement="bottom"
                >
                  <i class="el-icon-question setStyle"></i>
                </el-tooltip>
                <label>{{ orderRecord.receivedOrderClothesNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>取衣单数</label>
                <label>{{ orderRecord.waitCommentOrderNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>取衣件数</label>
                <label>{{ orderRecord.waitOrderClothesNum }}</label>
              </div>
            </div>
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>小程序订单</label>
                <label>{{ orderRecord.miniOrderNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>美团/点评</label>
                <label>{{ orderRecord.meiOrderNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>太保团单单数</label>
                <label>{{ orderRecord.lianGroupBuyNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>退卡数量(有效)</label>
                <label>{{ refundRecord.validQuanRefundNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>退卡数量(无效)</label>
                <label>{{ refundRecord.invalidQuanRefundNum }}</label>
              </div>
            </div>
            <div class="textView">
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>退衣单数</label>
                <label>{{ orderRecord.refundOrderNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>退衣件数</label>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="退衣件数：统计已退衣衣物件数(取消衣物/操作退款的衣物)"
                  placement="bottom"
                >
                  <i class="el-icon-question setStyle"></i>
                </el-tooltip>
                <label>{{ orderRecord.refundOrderClothesNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>办卡数量(有效)</label>
                <label>{{ memberCardUserRecord.validCardNum }}</label>
              </div>
              <div>
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>办卡数量(无效)</label>
                <label>{{ memberCardUserRecord.invalidCardNum }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customerRepView">
        <div class="subTView">
          洗后付回款
        </div>
        <div class="contentView" v-loading="test" element-loading-text="加载中">
          <div class="customerTypeRepView">
            <div class="textView">
              <div @click="customerRepWindow(4)" style="cursor: pointer;">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>现金</label>
                <label>{{ orderRecord.laterCashAmount }}</label>
              </div>
              <div @click="customerRepWindow(3)" style="cursor: pointer;">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>历史余额</label>
                <label>{{ orderRecord.laterBalanceAmount }}</label>
              </div>
              <div @click="customerRepWindow(11)" style="cursor: pointer;">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>通享余额</label>
                <label>{{ orderRecord.laterUniversalBalanceAmount }}</label>
              </div>
              <div @click="customerRepWindow(6)" style="cursor: pointer;">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>收钱吧</label>
                <label>{{ orderRecord.laterCollectAmount }}</label>
              </div>
              <div @click="customerRepWindow(1)" style="cursor: pointer;">
                <img
                  src="https://static.bokao2o.com/wisdomCashier/images/slot_0.png"
                />
                <label>微信</label>
                <label>{{ orderRecord.laterWechaAmount }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position_box" @click="getStoreBusinessSpecialDescById">
        <img src="../../assets/img/特殊说明.png" />
      </div>
    </div>
    <!--弹框编辑-->
    <pop-view
      id="order-orderEditPopView"
      :show="reportRemarkView"
      title="特殊说明"
      top="120px"
      width="430px"
      right="25px"
      @cancel="reportRemarkView = false"
    >
      <div class="textView">
        <textarea placeholder="填写特殊说明" v-model="specialDesc"></textarea>
      </div>
      <div class="btnView">
        <button @click="handlerReportRemark">编辑</button>
      </div>
    </pop-view>

    <pop-view
      :show="isShow"
      title="洗后付回款"
      @cancel="isShow = false"
      top="120px"
      width="1000px"
    >
      <el-table
        :data="tableData"
        stripe
        style="width: 100%; padding: 0 10px 15px;"
      >
        <el-table-column
          prop="orderNumber"
          label="订单号"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="联系人"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="userPhone"
          label="电话"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="orderStatus"
          label="订单状态"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="clothesNum"
          label="件数"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="orderTime"
          label="下单时间"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="payTime"
          label="支付时间"
          width="150"
        ></el-table-column>
        <el-table-column prop="payWay" label="支付方式"></el-table-column>
        <el-table-column
          prop="actuallyPaidPrice"
          label="实付金额"
        ></el-table-column>
      </el-table>
    </pop-view>
  </div>
</template>

<script>
import { daily, getWashAfter } from "@/api/clothes/order";
import {
  getStoreBusinessSpecialDescByThatDay,
  saveStoreBusinessSpecialDesc,
  updateStoreBusinessSpecialDesc,
} from "@/api/store/storeBusinessSpecialDesc";
import { mapGetters } from "vuex";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import { getStoreList } from "@/api/store/user";
import store from "../../store";
import { getLodop } from "@/print/LodopFuncs";
import PopView from "@/components/PopView";

export default {
  name: "index",
  components: {
    PopView,
  },
  data() {
    return {
      test: false,
      isShow: false,
      tableData: [], // 洗后付弹窗数据
      // 订单相关
      orderRecord: {},
      // 会员
      memberCardUserRecord: {},
      // 退款
      refundRecord: {},
      commission: {},
      timeStart: dateFormat(new Date()),
      timeEnd: dateFormat(new Date()),
      userId: 0,
      tenantId: 0,
      storeList: [],
      dateRange: [],
      radio2: "1",
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = 365 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        },
      },
      printStartTime: "",
      printEndTime: "",
      reportRemarkView: false,
      specialDesc: "",
      id: "",
      totalPic: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.dateRange = [this.timeStart, this.timeEnd];
    this.getStoreListByUserName();
  },
  methods: {
    dateFormat,

    // 洗后付回款弹窗
    async customerRepWindow(index) {
      this.isShow = true;
      const params = new URLSearchParams();
      params.append(
        "beginTime",
        this.dateRange === null ? dateFormat(this.timeStart) : this.dateRange[0]
      );
      params.append(
        "endTime",
        this.dateRange === null ? dateFormat(this.timeEnd) : this.dateRange[1]
      );
      params.append("userId", this.userId);
      params.append("tenantId", this.tenantId);
      params.append("payWay", index);
      const { data: res } = await getWashAfter(params);
      console.log(res);
      this.tableData = res.data;
    },
    GetDateStr(AddDayCount) {
      let t = new Date();
      let t_s = t.getTime();
      t.setTime(t_s + 1000 * 60 * 60 * 24 * AddDayCount);
      return t;
    },
    openFullScreen2() {
      this.test = true;
      setTimeout(() => {
        this.test = false;
      }, 2000);
    },
    gotoPage() {
      this.$router.push("/home/storeCommission");
    },
    getStoreListByUserName() {
      if (getToken()) {
        const userInfo = store.getters.userInfo;
        this.tenantId = userInfo.storeId;
        this.getStoreList(userInfo.phone);
        this.getMemberCardStatistics();
      }
    },
    // 当前账号人的
    membercardStatistics() {
      if (getToken()) {
        const userInfo = store.getters.userInfo;
        this.userId = userInfo.userId;
        this.getMemberCardStatistics();
      }
    },
    // 门店的
    getMemberCardStatistics() {
      this.test = true;
      const params = new URLSearchParams();
      params.append(
        "beginTime",
        this.dateRange === null ? dateFormat(this.timeStart) : this.dateRange[0]
      );
      params.append(
        "endTime",
        this.dateRange === null ? dateFormat(this.timeEnd) : this.dateRange[1]
      );
      params.append("userId", this.userId);
      params.append("tenantId", this.tenantId);
      daily(params)
        .then((response) => {
          this.test = false;
          this.orderRecord = response.data.data.orderRecord;
          this.memberCardUserRecord = response.data.data.memberCardUserRecord;
          this.refundRecord = response.data.data.refundRecord;
          this.totalPic = response.data.data.totalIncome;
          this.commission = response.data.data.commissionDetails;
        })
        .catch((error) => {
          console.log(error);
          this.test = false;
        });
    },
    getStoreList(username) {
      const that = this;
      if (username) {
        const params = new URLSearchParams();
        params.append("phone", username);
        getStoreList(params).then((response) => {
          console.log(response);
          // this.storeList = response.data.data.records
          if (response.data.code === 0) {
            that.storeList = response.data.data;
          }
        });
      }
    },
    changeQuery(val) {
      // 本人查询
      if (val == 2) {
        this.membercardStatistics();
      } else {
        this.userId = 0;
        this.getMemberCardStatistics();
      }
    },
    getPrint(type) {
      let defaultName;
      let LODOP = getLodop();
      LODOP.SET_LICENSES(
        "",
        "660FDFD7D9F7F7101A272F2EB57C306A",
        "C94CEE276DB2187AE6B65D56B3FC2848",
        ""
      );

      let arr = [];
      let iPrinterCount = LODOP.GET_PRINTER_COUNT();
      for (let i = 0; i < iPrinterCount; i++) {
        arr.push({
          label: LODOP.GET_PRINTER_NAME(i),
          value: i,
        });
      }

      let labelList = [];
      if (type === 1) {
        //小票
        defaultName = LODOP.GET_PRINTER_NAME("-1"); //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
        arr.forEach((item) => {
          if (item.label === defaultName) {
            this.printIndex = item.value;
          }
        });
      } else {
        //水洗唛
        labelList.push("TSC");
        labelList.push("水洗唛打印机");
        labelList.push("HPRT XT300");
        labelList.push("HPRT XT300 - ZPL");
        arr.forEach((item) => {
          for (let i = 0; i < labelList.length; i++) {
            if (item.label === labelList[i]) {
              console.log(labelList[i]);
              this.printIndex = item.value;
            }
          }
        });
      }
    },

    printPaper() {
      this.$confirm("确认要打印小票嘛?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let LODOP = getLodop();
          LODOP.SET_LICENSES(
            "",
            "660FDFD7D9F7F7101A272F2EB57C306A",
            "C94CEE276DB2187AE6B65D56B3FC2848",
            ""
          );
          this.getPrint(1);

          LODOP.PRINT_INIT("营业数据统计单"); //首先一个初始化语句
          LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "营业数据统计单");
          //门店信息

          let height = 0;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            (this.radio2 === "1" ? "门店" : "店长") + "营业统计单"
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
          height = height + 6;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            this.storeList.filter((store) => store.id === this.tenantId)[0].name
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
          //时间
          let startTime =
            this.dateRange === null
              ? dateFormat(this.timeStart)
              : this.dateRange[0];
          let endTime =
            this.dateRange === null
              ? dateFormat(this.timeEnd)
              : this.dateRange[1];
          height = height + 6;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            startTime + "——" + endTime
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          //线
          height = height + 6;
          LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

          //营业统计
          height = height + 3;
          LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "营业统计");
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          height = height + 7;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "会员充值：" +
              this.memberCardUserRecord.memberRechargeSuccessAmount +
              "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "洗衣收入：" + this.orderRecord.laundryAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "洗衣退款：" + this.refundRecord.clothesRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "充值退款：" + this.refundRecord.chuRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "退款金额：" + this.refundRecord.totalRefundPrice + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "实收金额：" +
              Number(
                this.memberCardUserRecord.memberRechargeSuccessAmount +
                  this.orderRecord.laundryAmount
              ).toFixed(2) +
              "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 6;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "总营收：" + this.totalPic + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          height = height + 6;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "总营收 = 实收金额 - 退款金额"
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          // //现金收入统计
          // height = height + 6;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "洗衣收入：" + this.orderRecord.laundryAmount + "元");
          // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          // //现金收入统计
          // height = height + 6;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "（现金支付、美团、口碑、小程序、联联团购、收钱吧）");
          // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

          // //+
          // height = height + 4;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "+");
          // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 16);
          // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          // //现金收入统计
          // height = height + 6;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员充值：" + this.memberCardUserRecord.memberRechargeSuccessAmount + "元");
          // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);

          // //+
          // height = height + 4;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "=");
          // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 16);
          // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          // //现金收入统计
          // height = height + 6;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "现金总收入：" + (this.orderRecord.laundryAmount + this.memberCardUserRecord.memberRechargeSuccessAmount) + "元");
          // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);

          //线
          height = height + 6;
          LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

          //收支明细统计
          height = height + 3;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "收入明细统计"
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          height = height + 7;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "现金洗衣：" + this.orderRecord.cashAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "收钱吧洗衣：" + this.orderRecord.shouAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "现金充值：" + this.memberCardUserRecord.cashAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "收钱吧充值：" + this.memberCardUserRecord.shouAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "历史卡消：" + this.orderRecord.blaceAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "洗后付：" + this.orderRecord.laterAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "美团/点评：" + this.orderRecord.meituanAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "小程序支付：" + this.orderRecord.miniAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "口碑：" + this.orderRecord.koubeiAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "太保团单：" + this.orderRecord.lianGroupBuyAmount + "元"
          );
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "通享卡消：" + this.orderRecord.universalBalanceAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "线上支付充值：" + this.memberCardUserRecord.hmAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          // height = height + 5;
          // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "洗衣退款：" + this.refundRecord.clothesRefundAmount + "元");
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          // LODOP.ADD_PRINT_TEXT(height + "mm", "37.5mm", "75mm", "4mm", "储值退款：" + this.refundRecord.chuRefundAmount + "元");
          // LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          //线
          height = height + 6;
          LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
          //退款明细统计
          height = height + 3;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "退款明细统计"
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
          height = height + 7;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "现金洗衣退款：" + this.refundRecord.cashRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "收钱吧洗衣退款：" + this.refundRecord.shouRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "现金充值退款：" + this.refundRecord.cashChuRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "收钱吧充值退款：" + this.refundRecord.shouChuRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "美团/点评退款：" + this.refundRecord.mtRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "小程序退款：" + this.refundRecord.miniRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "太保团单退洗：" + this.refundRecord.tbRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "权益卡退款：" + this.refundRecord.quanRefund + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "余额(历史卡消)退款：" +
              this.refundRecord.balanceRefundAmount +
              "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "余额(通享卡消)退款：" +
              this.refundRecord.universalBalanceRefundAmount +
              "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "线上支付充值退款：" + this.refundRecord.hmChuRefundAmount + "元"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          //线
          height = height + 6;
          LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

          //其他数据统计
          height = height + 3;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "3mm",
            "其他数据统计"
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          height = height + 7;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "收衣单数：" + this.orderRecord.receivedOrderNum + "单"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "收衣件数：" + this.orderRecord.receivedOrderClothesNum + "件"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "取衣单数：" + this.orderRecord.waitCommentOrderNum + "单"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "取衣件数：" + this.orderRecord.waitOrderClothesNum + "件"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "退衣单数：" + this.orderRecord.refundOrderNum + "单"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "退衣件数：" + this.orderRecord.refundOrderClothesNum + "件"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "小程序订单：" + this.orderRecord.miniOrderNum + "单"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "美团/点评：" + this.orderRecord.meiOrderNum + "单"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "办卡数量(有效)：" + this.memberCardUserRecord.validCardNum + "张"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "退卡数量(有效)：" + this.refundRecord.validQuanRefundNum + "张"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "办卡数量(无效)：" + this.memberCardUserRecord.invalidCardNum + "张"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "37.5mm",
            "75mm",
            "4mm",
            "退卡数量(无效)：" + this.refundRecord.invalidQuanRefundNum + "张"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "太保团单单数：" + this.orderRecord.lianGroupBuyNum + "张"
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

          //线
          height = height + 6;
          LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

          height = height + 5;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "打印人：" + this.userInfo.realName
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
          height = height + 6;
          LODOP.ADD_PRINT_TEXT(
            height + "mm",
            "0mm",
            "75mm",
            "4mm",
            "打印时间：" + this.getTime()
          );
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);

          LODOP.SET_PRINTER_INDEX(this.printIndex);
          LODOP.PRINT();
          // LODOP.PREVIEW();
        })
        .catch(() => {});
    },

    getTime() {
      var date1 = new Date();
      var year = date1.getFullYear();
      var month = date1.getMonth() + 1;
      var day = date1.getDate();
      var hours = date1.getHours();
      var minutes = date1.getMinutes();
      var seconds = date1.getSeconds();
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    getStoreBusinessSpecialDescById() {
      getStoreBusinessSpecialDescByThatDay().then((response) => {
        console.log(response);
        // this.storeList = response.data.data.records
        if (response.data.code === 0) {
          console.log(response.data.data);
          if (response.data.data == null) {
            this.specialDesc = "";
          } else {
            this.specialDesc = response.data.data.desc;
            this.id = response.data.data.id;
          }
        }
      });
      this.reportRemarkView = true;
    },
    handlerReportRemark() {
      // 不为空则是编辑
      if (this.id !== "") {
        updateStoreBusinessSpecialDesc({
          id: this.id,
          desc: this.specialDesc,
        }).then((response) => {
          console.log(response);
          // this.storeList = response.data.data.records
          if (response.data.code === 0) {
            this.reportRemarkView = false;
            this.$message.success("操作成功");
          }
        });
      } else {
        saveStoreBusinessSpecialDesc({
          desc: this.specialDesc,
        }).then((response) => {
          console.log(response);
          // this.storeList = response.data.data.records
          if (response.data.code === 0) {
            this.reportRemarkView = false;
            this.$message.success("操作成功");
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .cell {
  text-align: center;
}

.setStyle {
  font-size: 16px;
  margin-left: 5px;
}

.reportIndexView {
  position: relative;
  background: #fff;
  font-family: PingFangSC-Regular;
  // padding: 75px 0 0 0;

  > .topView {
    // position: fixed;
    // top: 0;
    // left: 76px;
    // right: 0;
    padding: 17.5px 0 17.5px 20px;
    overflow: hidden;
    z-index: 100;
    background: #fff;

    > .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 24px;
      font-family: PingFang-SC-Bold;
      line-height: 40px;
    }

    > .dateView {
      float: left;
      margin-left: 35px;
      height: 40px;
      line-height: 40px;
    }
  }

  .incomeRepView {
    display: flex;
    margin: 0 30px;
    border-bottom: 0.5px dashed rgba(220, 220, 220, 0.7);
    padding-bottom: 12px;

    > .payRepView {
      flex: 3;

      > .contentView {
        display: flex;
        padding: 10px 10px 10px 0;
        border-right: 1px dashed #333;

        > .chartView {
          position: relative;
          width: 120px;
          height: 120px;
          margin-right: 35px;
          overflow: hidden;

          > .totalAmtView {
            width: 120px;
            height: 120px;
            background: transparent;

            > .bcView {
              -webkit-transition: -webkit-transform 1s ease-in-out,
                -webkit-opacity 0.5s;
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 90px;
              height: 70px;
              background: #fff;
              border-radius: 50%;
              text-align: center;
              line-height: 20px;

              > .textView {
                margin-top: 15px;
                font-size: 13px;
                color: #999;
              }

              > .amtView {
                font-family: PingFangSC-Semibold;
                font-size: 17px;
                padding-top: 18px;
                color: #333;
              }
            }
          }
        }

        > .payRepListView {
          flex: 1;
          height: 120px;
          overflow-x: hidden;
          overflow-y: auto;
          padding-right: 20px;

          > div {
            position: relative;
            display: inline-flex;
            padding: 5px 0;
            line-height: 30px;
            font-size: 14px;
            color: #333;
            width: 50%;
            padding-left: 18px;
            height: 40px;

            > img {
              position: absolute;
              width: 10px;
              height: 10px;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            > div {
              flex: 1;
              font-size: 15px;
              text-align: right;
              color: #ff5e56;
              font-family: PingFangSC-Semibold;
            }
          }

          > div:nth-child(odd) {
            padding-right: 10px;
          }

          > div:nth-child(even) {
            padding-left: 28px;

            > img {
              left: 10px;
            }
          }
        }
      }
    }

    > .cardRepView {
      flex: 2;
      height: 180px;
      padding-left: 30px;

      > .listView {
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        height: 140px;
        .totalCommission {
          font-size: 18px;
          p {
            text-align: center;
            cursor: pointer;
          }
        }
        div {
          margin-top: 10px;
          margin-left: 15px;
          font-size: 14px;
          cursor: pointer;
          img {
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }
          span:nth-of-type(2) {
            margin-left: 15px;
            color: #ff5e56;
          }
        }
        // > .listItem {
        //   display: flex;
        //   padding: 10px 0 10px 30px;
        //   line-height: 20px;
        //   font-size: 14px;

        //   > .label-name {
        //     flex: 3;
        //     color: #333;
        //   }

        //   > .label-cnt {
        //     flex: 1;
        //     color: #999;
        //   }

        //   > .label-amt {
        //     flex: 2;
        //     color: #ff5e56;
        //     font-size: 15px;
        //     text-align: right;
        //     font-family: PingFangSC-Semibold;
        //   }
        // }

        > .listItem:nth-child(3n + 1) {
          background: #fff
            url(https://static.bokao2o.com/wisdomCashier/images/Report_Card_0.png)
            left center / 28px no-repeat;
        }
      }
    }
  }

  .customerRepView {
    margin: 0 30px;
    padding: 15px 0;
    border-bottom: 0.5px dashed rgba(220, 220, 220, 0.7);

    > .contentView {
      display: flex;

      > div {
        display: flex;
        flex: 1;

        > .textView {
          flex: 2;
          padding: 10px 0;
          color: #333;
          font-size: 14px;

          > div {
            height: 30px;
            line-height: 30px;

            > img {
              width: 10px;
              height: 10px;
              margin-right: 15px;
            }

            /*> div {*/
            /*    flex: 1;*/
            /*    font-size: 15px;*/
            /*    text-align: right;*/
            /*    color: #FF5E56;*/
            /*    font-family: PingFangSC-Semibold;*/
            /*}*/

            > label:last-child {
              color: #666;
              font-size: 14px;
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
}

.reportIndexView .subTView {
  height: 40px;
  line-height: 40px;
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #333;
  font-weight: 700;

  > span {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #999;
    margin-left: 10px;
  }
}

label {
  cursor: default;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.position_box {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
  }
}

#order-orderEditPopView > .popView-contentView {
  > .textView {
    padding: 0 30px;

    > textarea {
      display: block;
      width: 100%;
      background: #f8f8f8;
      min-height: 220px;
      padding: 15px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      border-radius: 6px;
      border: none;
      outline: none;
      resize: none;
    }
  }

  > .btnView {
    padding: 20px;
    text-align: center;

    > button {
      width: 100px;
      line-height: 44px;
      height: 44px;
      background: #3370ff;
      font-size: 16px;
      color: #fff;
      border-radius: 6px;
    }
  }
}
</style>

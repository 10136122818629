import request from '@/utils/request'

/**
 * 保存门店收入收支
 * @param data
 * @returns {AxiosPromise}
 */
export function saveStoreBusinessSpecialDesc(data) {
    return request({
        url: '/sys/storeBusinessSpecialDesc',
        method: 'post',
        data: data,
    })
}
export function updateStoreBusinessSpecialDesc(data) {
    return request({
        url: '/sys/storeBusinessSpecialDesc',
        method: 'put',
        data: data,
    })
}

export function getStoreBusinessSpecialDescById(id) {
    return request({
        url: '/sys/storeBusinessSpecialDesc/' + id,
        method: 'get'
    })
}
export function getStoreBusinessSpecialDescByThatDay() {
    return request({
        url: '/sys/storeBusinessSpecialDesc/thatDay',
        method: 'get'
    })
}